import { Handle, Position, useConnection } from '@xyflow/react';
import Shape from '../../../../components/SVGDrawer/shapes/Shape';
import getStepType from '../stepTypes';
import NodeShape from './NodeShape';

const HANDLE_STYLE = { 
  background: 'blue',
  borderRadius: 0,
  border: 'none',
  opacity: 0
//   opacity: 0.5
};

const VERTICAL_SOURCE_HANDLE_STYLE = {
  ...HANDLE_STYLE,
};

const HORIZONTAL_SOURCE_HANDLE_STYLE = {
  ...HANDLE_STYLE,
};

const TARGET_HANDLE_STYLE = {
  ...HANDLE_STYLE,
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  transform: 'none',
  border: 'none',
  opacity: 0,
//   opacity: 0.1
};

const HANDLE_THICKNESS = 3;

export default function UWENode(props) {
  const { 
    id, 
    positionAbsoluteX: x,
    positionAbsoluteY: y,
    width, 
    height,
    selected,
    data: { label, stepdef: { type } }
  } = props;

  const connection = useConnection();
  const isTarget = connection.inProgress && connection.fromNode.id !== id;

  const stepType = getStepType(type);
  const { shape, svgIcon: icon } = stepType;

  return (
    <div
      style={{
        cursor: 'pointer'
      }}
    >
      <NodeShape
        className="step"
        x={x}
        y={y}
        shape={shape}
        icon={icon}
        width={2*width}
        height={2*height}
        selected={selected}
        isDragging={false}
      />
      <div
        style={{
          width,
          height,
          borderStyle: isTarget ? 'dashed' : 'solid',
          backgroundColor: 'white',
          borderWidth: '0px'
          // borderRadius: "10px",
        }}
      >
        {/* If handles are conditionally rendered and not present initially, you need to update the node internals https://reactflow.dev/docs/api/hooks/use-update-node-internals/ */}
        {/* In this case we don't need to use useUpdateNodeInternals, since !isConnecting is true at the beginning and all handles are rendered initially. */}
        {!connection.inProgress && (
          <>
            <Handle
              style={{
                ...VERTICAL_SOURCE_HANDLE_STYLE,
                width: HANDLE_THICKNESS,
                height: '100%',
              }}
              position={Position.Left}
              type="source"
            />
            <Handle
              style={{
                ...HORIZONTAL_SOURCE_HANDLE_STYLE,
                width: '100%',
                height: HANDLE_THICKNESS,
              }}
              position={Position.Top}
              type="source"
            />
            <Handle
              style={{
                ...VERTICAL_SOURCE_HANDLE_STYLE,
                width: HANDLE_THICKNESS,
                height: '100%',
                marginLeft: width - 2*HANDLE_THICKNESS,
              }}
              position={Position.Right}
              type="source"
            />
            <Handle
              style={{
                ...HORIZONTAL_SOURCE_HANDLE_STYLE,
                width: '100%',
                height: HANDLE_THICKNESS,
                marginTop: height- 2*HANDLE_THICKNESS,
              }}
              position={Position.Bottom}
              type="source"
            />
          </>

        )}
        {/* We want to disable the target handle, if the connection was started from this node */}
        {(!connection.inProgress || isTarget) && (
          <Handle 
            style={TARGET_HANDLE_STYLE}
            type="target" 
            isConnectableStart={false} 
          />
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            width,
            height,
          }}
        >
          {label}
        </div>
      </div>
    </div>
  );
}
import { useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { Redirect } from 'react-router';
import { t } from '@lingui/macro';
import { useHistory } from 'react-router-dom';
import FormComponent from '../../../components/FormComponent';
import Loader from '../../../components/Loader';
import Notification from '../../../components/Notification';
import ThemeSchemaApi from '../../../api/ThemeSchemaApi';
import useLoader from '../../../util/useLoader';
import parseFormDefinition from '../../../util/parseFormDefinition';

function ThemeEditor({ themeId, setThemeId }) {
  const [loadingTheme, errorLoadingTheme, loadTheme] = useLoader();
  const [theme, setTheme] = useState();
  const history = useHistory();

  const nameProperties = {
    name: { title: t`Name`, type: 'string', 'map:field': 'name' },
  };

  const uploadProperties = {
    logo: { title: 'Logo', type: 'string', format: 'data-url' },
    ico: { title: 'ICO Logo Icon', type: 'string', format: 'data-url' },
  };

  const fontProperties = {
    'fonts-url': {
      title: 'Google Fonts URL',
      type: 'string',
      'map:field': 'fontSettings.fonts-url',
    },
    'font-family': { title: 'font-family', type: 'string', 'map:field': 'fontSettings.font-family' },
    'font-optical-sizing': { title: 'font-optical-sizing', type: 'string', 'map:field': 'fontSettings.font-optical-sizing' },
    'font-weight': { title: 'font-weight', type: 'number', 'map:field': 'fontSettings.font-weight' },
    'font-style': { title: 'font-style', type: 'string', 'map:field': 'fontSettings.font-style' },
    'font-size': { title: 'font-size', type: 'string', 'map:field': 'fontSettings.font-size' },
  };

  const colorProperties = {
    primary: { title: 'primary', type: 'string', 'map:field': 'colors.color_primary' },
    secondary: { title: 'secondary', type: 'string', 'map:field': 'colors.color_secondary' },
    background: { title: 'background', type: 'string', 'map:field': 'colors.color_background' },
    main: { title: 'main', type: 'string', 'map:field': 'colors.color_main' },
    white: { title: 'white', type: 'string', 'map:field': 'colors.color_white' },
    black: { title: 'black', type: 'string', 'map:field': 'colors.color_black' },
    warning: { title: 'warning', type: 'string', 'map:field': 'colors.color_warning' },
    success: { title: 'success', type: 'string', 'map:field': 'colors.color_success' },
    danger: { title: 'danger', type: 'string', 'map:field': 'colors.color_danger' },
    'gray-100': { title: 'gray-100', type: 'string', 'map:field': 'colors.color_gray-100' },
    'gray-200': { title: 'gray-200', type: 'string', 'map:field': 'colors.color_gray-200' },
    'gray-300': { title: 'gray-300', type: 'string', 'map:field': 'colors.color_gray-300' },
    'gray-400': { title: 'gray-400', type: 'string', 'map:field': 'colors.color_gray-400' },
    'gray-500': { title: 'gray-500', type: 'string', 'map:field': 'colors.color_gray-500' },
    'gray-600': { title: 'gray-600', type: 'string', 'map:field': 'colors.color_gray-600' },
    'gray-700': { title: 'gray-700', type: 'string', 'map:field': 'colors.color_gray-700' },
    'gray-800': { title: 'gray-800', type: 'string', 'map:field': 'colors.color_gray-800' },
    'gray-900': { title: 'gray-900', type: 'string', 'map:field': 'colors.color_gray-900' },
  };

  const formSchema = {
    properties: {
      themeName: {
        title: t`Name`,
        type: 'object',
        properties: nameProperties,
      },
      colors: {
        title: t`Colors`,
        type: 'object',
        properties: colorProperties,
      },
      upload: {
        title: t`Upload Logos`,
        type: 'object',
        properties: uploadProperties,
      },
      font: {
        title: t`Font`,
        type: 'object',
        properties: fontProperties,
      },
    },
    required: Object.keys({ ...nameProperties, ...colorProperties, ...fontProperties }),
  };

  function renderFormSubmitted() {
    return <Redirect path="../system-settings/themeEditor" />;
  }

  function getEditForm() {
    const Form = FormComponent({
      ...parseFormDefinition({
        schemaProps: { type: 'object', required: formSchema.required },
        schema: formSchema.properties,
      }),
      submitButtons: {
        cancel: {
          text: t`Cancel`,
          className: 'btn-warning',
          ignoreValidation: true,
          onBeforeSubmit: submitEvent => {
            submitEvent.preventDefault();
            setThemeId();
            //history.push('/system-settings/themeEditor');
          },
        },
        save: {
          text: t`Save`,
          className: 'btn-success',
          onBeforeSubmit: async submitEvent => {
            submitEvent.preventDefault();

            /* TODO - Implement update theme 
            const { formData, objectData, formObject } = submitEvent;
            const toDbData = mapToDatabase(formData);

            const { success, data } = await ThemeSchemaApi.update(formObject);

            if (success) {
              history.push('/system-settings/themeEditor');
            } */
          },
        },
      },
      loadData: async ({ params }) => {
        const resp = await ThemeSchemaApi.getTheme(parseInt(themeId));
        return resp.data;
      },
      parseProps: ({ themeId, setThemeId, history }) => ({
        auth: useContext(AuthContext),
        history,
        setThemeId,
        themeId,
      }),
      renderFormSubmitted,
    });

    return (
      <div>
        <Form themeId={themeId} setThemeId={setThemeId} history={history} />
      </div>
    );
  }

  return loadingTheme ? (
    <Loader />
  ) : (
    <>
      {errorLoadingTheme && <Notification error={errorLoadingTheme} />}
      {getEditForm()}
    </>
  );
}

export default ThemeEditor;

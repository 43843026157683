import React, { useMemo } from 'react';
import { FormGroup } from 'reactstrap';
import DASHBOARD_COMPONENTS from '../Dashboard/fields';


function WorktrayEntitySelectField(props) {
  const WorkTray = DASHBOARD_COMPONENTS.worktray;
  const {
    formData,
    schema: {
      title,
      worktray
    },
    uiSchema: {
      'akc:requiredIfVisible': akcRequiredIfVisible,
      'ui:readonly': readonly,
    },
    disabled,
    required: requiredProp,
    onChange,
  } = props;
  const required = requiredProp || akcRequiredIfVisible;

  const element = useMemo(
    () => ({
      ...worktray,
      selectable: true,
      title: `${title}${required ? '*' : ''}`,
    }),
    [title, required, worktray]
  );
  const scope = useMemo(
    () => ({
      selected: formData,
    }),
    [formData]
  );

  return (<FormGroup disabled={readonly || disabled}>
    <WorkTray
      element={element}
      scope={scope}
      selected={formData}
      setSelected={onChange}
    />
  </FormGroup>);
}


export default WorktrayEntitySelectField;

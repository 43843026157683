

class TypesSet {
  constructor(mapping, aliases) {
    this.mapping = mapping;
    this.aliases = aliases;
  }

  select(type) {
    const alias = this?.aliases?.[type];
    type = alias ?? type;

    return this.mapping[type] ?? this.mapping.default;    
  }

  call(type, ...args) {
    return this.apply(type, args);
  }

  apply(type, args) {
    const instance = this.select(type);

    return instance.apply(this, args);
  }
}


export default TypesSet;
import { Label, Input, Row, Col } from 'reactstrap';
import dayjs from 'dayjs';

const EntityInfoLayout = ({ info = {}, onObjectSchemaChange }) => {
  return (
    <>
      <h3 className="text-white p-2" style={{ backgroundColor: '#306aac' }}>
        Object Schema
      </h3>
      <Row className="py-2">
        <Col className="pl-5" xs="4">
          <Label className="h5" for="name">
            Name
          </Label>
          <Input className="form-control-lg" name="name" value={info.name || ''} onChange={e => onObjectSchemaChange({ name: e.target.value })} />
        </Col>
        <Col xs="4">
          <Label className="h5" for="version">
            version
          </Label>
          <Input type="text" readOnly className="pl-3 form-control-plaintext" value={info.version || ''} name="version" />
        </Col>
        <Col className="pr-5" xs="4">
          <Label className="h5" for="lastUpdated">
            Last updated
          </Label>
          <Input readOnly name="lastUpdated" value={dayjs(info.lastUpdated).format('MM/DD/YY')} />
        </Col>
      </Row>
    </>
  );
};

export default EntityInfoLayout;

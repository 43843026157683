import { Trans } from '@lingui/macro';
import React, { useState, useEffect } from 'react';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import DashboardApi from '../../../api/DashboardApi';
import Loader from '../../../components/Loader';
import Notification from '../../../components/Notification';
import useResourceLoader from '../../../util/useResourceLoader';
import useLoader from '../../../util/useLoader';
import JsonTreeEditor from '../../../components/JsonTreeEditor';
import useSearchParams from '../../../util/useSearchParams';
import DashboardDetailsEditor from './DashboardDetailsEditor';

import JsonEditor from '../../../components/JsonEditor';

const SHOW_TABS = {
  dashboard: 'Dashboard',
  tree: 'Tree',
  json: 'Json',
};

function EditDashboard({ dashboardId, setDashboard: setDashboardId }) {
  const [dashboard, loadingDashboard, errorLoadingDashboard, setDashboard] = useResourceLoader(
    (
      () => dashboardId === 'new' ? {
        order: 0,
        slug: 'new-dashboard',
        name: 'New Dashboard',
        image: {
          icon: 'fa fa-file',
        },
        definition: {
          type: 'dashboard',
          elements: [],
        },
      } : DashboardApi.get({ id: dashboardId })
    ),
    []
  );

  const [[show, setShow]] = useSearchParams({
    show: 'dashboard',
  });

  const [loadingSetDashboard, errorLoadingSetDashboard, loadFn] = useLoader();
  const loading = loadingDashboard || loadingSetDashboard;
  const error = errorLoadingDashboard || errorLoadingSetDashboard;
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [dirtyDashboard, setDirtyDashboard] = useState();

  const currentDashboard = dirtyDashboard || dashboard;

  function save() {
    return loadFn(async () => {
      if (dashboardId === 'new') {
        const saveOS = await DashboardApi.create(dirtyDashboard);
        setDashboard(saveOS);
        setDashboardId(saveOS.id);
      } else {
        const savedOS = await DashboardApi.update(dashboardId, dirtyDashboard);
        setDashboard(savedOS);
      }
    });
  }

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  return loading ? (
    <Loader />
  ) : (
    <>
      {error ? (
        <Notification error={error} />
      ) : null}
      {dashboard ? (
        <div>
          <h3>
            {dashboardId === 'new' ? (
              <>
                <Trans>Creating Dashboard: {dashboard.name}</Trans>
              </>
            ) : (
              <>
                <Trans>Editing Dashboard: {dashboard.name}</Trans>
              </>
            )}
          </h3>
          <UncontrolledDropdown>
            <DropdownToggle caret>{SHOW_TABS[show]}</DropdownToggle>
            <DropdownMenu>
              {Object.entries(SHOW_TABS).map(([key, title]) => (
                <DropdownItem key={key} onClick={() => setShow(key)}>
                  {title}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
          {show === 'tree' ? (
            <JsonTreeEditor mode="object" value={currentDashboard} onChange={setDirtyDashboard} />
          ): null}
          {show === 'json' ? (
            <JsonEditor value={currentDashboard} onChange={setDirtyDashboard} onSaveForm={save}/>
          ): null}
          {show === 'dashboard' ? (
            <DashboardDetailsEditor dashboard={currentDashboard} onChange={setDirtyDashboard} />
          ): null}
          <Button color="success" disabled={!dirtyDashboard} onClick={save}>
            <Trans>Save</Trans>
          </Button>
        </div>
      ) : null}
    </>
  );
}

export default EditDashboard;

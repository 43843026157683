import fetchApi from '../util/fetchApi';
import checkErrors from './checkErrors';

export const ThemeSchemaApi = {
  async delete(id) {
    return checkErrors(await fetchApi.api.delete(`manage/themes/${id}`));
  },
  async duplicate(id) {
    return checkErrors(await fetchApi.api.post(`manage/themes/duplicate/${id}`));
  },
  async getTheme(id) {
    return checkErrors(await fetchApi.api.get(`manage/themes/${id}`));
  },
  async getThemes() {
    return checkErrors(await fetchApi.api.get('manage/themes/all'));
  },
  async update(theme) {
    return checkErrors(await fetchApi.api.put(`manage/themes/${theme.id}`, { data: theme }));
  },
};

export default ThemeSchemaApi;

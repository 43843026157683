import { t } from '@lingui/macro';
import Dashboard from '../../../components/Dashboard/index';
import JobApi from '../../../api/JobApi';


const ListJobs = Dashboard.HOC({
  schema: {
    'title': t`Jobs`,
    'type': 'job',
    'elements': [{
      'title': t`Scheduled Tasks`,
      'type': 'worktray',
      ':className': 'col-lg-12',
      'resource': 'manage/jobs/scheduled-tasks',
      'tableActions': [{ 'type': 'reload', 'auto': true, 'period': 15 }],
      'columns': [
        { 'title': t`Job #`, 'map:field': 'jobId' },
        { 'title': t`Name`, 'map:field': 'triggerKey.name' },
        { 'title': t`Fire At`, 'map:field': 'nextFireTime', 'format': 'date' },
      ]
    }, {
      'title': t`Background Jobs`,
      'type': 'worktray',
      ':className': 'col-lg-12',
      'resource': 'manage/jobs/background-jobs',
      'tableActions': [{ 'type': 'reload' }],
      'columns': [
        { 'title': t`Job #`, 'sortkey': 'id', 'map:field': 'id' },
        { 'title': t`Name`, 'sortkey': 'triggerName', 'map:field': 'triggerName' },
        { 'title': t`Status`, 'sortkey': 'status', 'map:field': {'expr': 'status ~> $lowercase'}},
        { 'title': t`Last Scheduled`, 'map:field': 'lastScheduled', 'format': 'date' },
        { 'title': t`Last Run`, 'map:field': 'lastRun', 'format': 'date' },
        {
          'title': t`Actions`, 'actions': [
            {
              'label': t`Rerun`,
              'requireExpr': '(status ~> $lowercase) in [\'failed\']',
              'onClick': (row, { loadFn, reload }) => {
                return loadFn(async () => {
                  await JobApi.rerunJob({ jobId: row.id });
                  reload();
                });
              },
            }, {
              'label': t`Hide`,
              'requireExpr': '(status ~> $lowercase) in [\'failed\', \'stuck\']',
              'onClick': (row, { loadFn, reload }) => {
                return loadFn(async () => {
                  await JobApi.hideJob({ jobId: row.id });
                  reload();
                });
              },
            }
          ]
        },
      ]
    }]
  }
});


export default ListJobs;
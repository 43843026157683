import { t } from '@lingui/macro';
import React, { useContext, useMemo } from 'react';

import { AuthContext } from '../../context/AuthContext';
import { getLabel } from '../../LanguageConstant';
import Dashboard from '../../components/Dashboard/index';


export function UserTable() {
  const auth = useContext(AuthContext);
  const { jediId } = auth?.user?.user || {};

  const schema = useMemo(
    () => ({
      'title': t`Users`,
      'type': 'dashboard',
      'elements': [
        {
          'title': t`Users`,
          'type': 'worktray',
          ':className': 'col-lg-12',
          'resource': 'manage/users',
          'tableActions': [
            {
              'label': getLabel().userRegister,
              'route': '/users/create',
              'require': [{ permission: 'ManageUsersInUnit' }],
            },
          ],
          'filters': [
            { 'key': 'firstName', 'title': t`First Name`, 'type': 'string' },
            { 'key': 'lastName', 'title': t`Last Name`, 'type': 'string' },
            { 'key': 'email', 'title': t`Email`, 'type': 'string' },
            {
              'key': 'profile',
              'title': t`User Type`,
              'type': 'number',
              'ui:field': 'LookupFormField',
              lookup: { api: 'profiles', resource: t`User Types`, id: 'id', label: 'displayName' }
            },
            {
              'key': 'orgUnit',
              'title': t`Organizational Unit`,
              'type': 'number',
              'ui:field': 'LookupFormField',
              lookup: { api: 'organizationalunits', resource: t`Organizational Units`, id: 'id', label: 'name' }
            },
            {
              'key': 'active',
              'title': t`Active`,
              'type': 'number',
              enum: [1, 0],
              enumNames: [t`Yes`, t`No`]
            },
            {
              'key': 'role',
              'title': t`Role`,
              'type': 'number',
              'ui:field': 'LookupFormField',
              lookup: { api: 'roles', resource: t`Roles`, id: 'id', label: 'name' },
            },
            { 'key': 'name', 'title': t`Name`, 'type': 'string' },
          ],
          'columns': [
            {
              title: 'Id',
              'sortkey': 'active',
              'map:field': { expr: [
                'id & ',
                '(jediId ? (',
                '  \' (jedi:\' & jediId & (',
                '    isDual ? \', dual\' : \'\'',
                '  ) & \')\'',
                ') : \'\')'
              ] }
            },
            {
              title: getLabel().name,
              'sortkey': 'name',
              'map:field': {expr: 'firstName & \' \' & lastName'}
            },
            {
              title: getLabel().organization,
              'sortkey': 'organization',
              'map:field': 'organizationalUnit.name'
            },
            {
              title: getLabel().position,
              'sortkey': 'position',
              'map:field': 'position'
            },
            {
              title: getLabel().active,
              'sortkey': 'active',
              'map:field': {expr: 'isActive ? \'Sí\' : \'No\''}
            },
            {
              'title': t`Actions`,
              'actions': [
                {
                  'label': t`Edit`,
                  'route': '/users/$id',
                }
              ]
            },
          ],
        },
      ],
    }),
    []
  );

  if (jediId) {
    return null;
  }

  return <Dashboard schema={schema} />;
}


export default UserTable;

import React from 'react';
import useSearchParams from '../../../util/useSearchParams';
import EditDashboard from './EditDashboard';
import ListDashboard from './ListDashboard';

function Dashboards() {
  const [
    [dashboard, setDashboard]
  ] = useSearchParams({
    dashboard: undefined
  });

  return dashboard ? (
        <EditDashboard dashboardId={dashboard} setDashboard={setDashboard} />
    ) : (
        <ListDashboard setDashboard={setDashboard} />
    );
}

export default Dashboards;

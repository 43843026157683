import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NodeShape(props) {
  const {
    className,
    shape,
    icon,
    width,
    height,
    pad = 10,
    selected,
    isDragging
  } = props;

  const svgWidth = width/2 + 10;
  const svgHeight = height/2 + 15;

  return (
    <div>
      {hasIcon(shape) && icon && 
                <FontAwesomeIcon 
                  style={{
                    position: 'absolute', 
                    left: -20, 
                    top: -20, 
                    width: 30, 
                    height: 30
                  }}  
                  icon={icon}
                />
      }
      <svg 
        version="1.1"
        width={svgWidth}
        height={svgHeight}
        style={{position: 'absolute', top: -2, left: -2}}
        fill="transparent"
      >
        <g 
          className={className}
          transform="translate(2, 2)"
          filter={isDragging ? 'url(#dropShadow)' : undefined}
        >
          {
            width && height && (
              <ShapeType
                width={width/2}
                height={height/2}
                shape={shape} 
                icon={icon} 
                pad={pad}
                selected={selected}
              />
            )
          }
        </g>
      </svg>
    </div>
  );
}

function ShapeType(props){
  const { shape } = props;
  const ShapeComponent = SHAPE_TYPES[shape];

  return <ShapeComponent {...props} />;
}

const hasIcon = (shape) => shape === 'rect' || shape === 'rect-bars';

const SHAPE_TYPES = {
  'rect': ({ width, height, selected }) => (
    <rect className="shape" width={width} height={height} stroke="black" strokeWidth={selected ? 3 : undefined} />
  ),
  'diamond': ({ width, height, pad, selected }) => (
    <path 
      className="shape"
      stroke="black"
      strokeWidth={selected ? 3 : undefined}
      d={`M 0 ${height/2}, L ${width/2 + pad} -${height}, L ${width + pad*2} ${height/2}, L ${width/2 + pad} ${height*2} Z`}
    />
  ),
  'rect-bars': ({ width, height, selected }) => (
    <g>
      <rect className="squaredCorners" width={width} height={height} stroke="black" strokeWidth={selected ? 3 : undefined}/>
      <rect className="squaredCorners" width={width / 8} height={height} x={`-${width/8}`} stroke="black" strokeWidth={selected ? 3 : undefined}/>
      <rect className="squaredCorners" width={width / 8} height={height} x={`${width}`} stroke="black" strokeWidth={selected ? 3 : undefined}/>
    </g>
  ),
  'document': ({ width, height, selected }) => (
    <path
      className="shape"
      stroke="black" 
      strokeWidth={selected ? 3 : undefined}
      d={`M 0 0 L ${width} 0 L ${width} ${height} Q ${width * 0.75} ${height * 0.6} ${width/2} ${height} Q ${width/4} ${height * 1.4} 0 ${height} Z`}
    />
  ),
  'oval': ({ width, height, selected }) => {
    width *= .95;
    height *= 2;

    return (
      <path
        className="shape"
        stroke="black"
        strokeWidth={selected ? 3 : undefined}
        d={`M ${width*.26},0 
                    C ${width*.11},0 0,${height*.11} 0,${height*.26} 
                    c 0,${height*.15} ${width*.11},${height*.26} ${width*.26},${height*.26} 
                    l ${width*.53},0 
                    c ${width*.15},0 ${width*.26},-${height*.11} ${width*.26},-${height*.26} 
                    C ${width*1.05},${height*.11} ${width*0.95},0 ${width*.8},0 
                    L ${width*.26},0 z`}
      />
    );
  },
  'default': ({ width, height, selected }) => (
    <rect className="shape" width={width} height={height} stroke="black" strokeWidth={selected ? 3 : undefined}/>
  ), 
  'circle': ({ width, height, selected }) => (
    <circle className="shape" cx={width / 2} cy={height / 2} r={width / 2} stroke="black" strokeWidth={selected ? 3 : undefined}/>
  ),
  'message': ({ width, height, selected }) => (
    <path
      className="shape"
      stroke="black"
      strokeWidth={selected ? 3 : undefined}
      d={`M 0 0 L ${width} 0 L ${width} ${height} L ${width - width*0.4} ${height} L ${width - width*0.6} ${height + height*0.4} L ${width - width*0.6} ${height} L 0 ${height} Z`}
    />
  ),
  'page': ({ width, height, selected }) => (
    <path
      className="shape"
      stroke="black"
      strokeWidth={selected ? 3 : undefined}
      d={`M 10 0 L ${width+width*0.05} 0 L ${width+width*0.05} ${height} L ${width*0.9 - width} ${height} L ${width*0.9 - width} ${height - height*0.6} Z`}
    />
  ),
  'hexagon': ({ width, height, selected }) => (
    <path
      className="shape"
      stroke="black"
      strokeWidth={selected ? 3 : undefined}
      d={`M 0 0 L ${width+width*0.05} 0 L ${width+width*0.25} ${height/2} L ${width+width*0.05} ${height} L 0 ${height} L ${width*0.75-width} ${height/2} Z`}
    />
  ),
};

export default NodeShape;
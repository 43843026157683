import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useDroppable } from '@dnd-kit/core';
import SchemaNode from './SchemaNode';

const DroppableZone = ({ schemaElements = [], onFieldUpdate, onRemove, isDragging }) => {
	const { setNodeRef, isOver } = useDroppable({
		id: 'editor-droppable',
	});
	if (!schemaElements) return null;

	return (
		<div
			ref={setNodeRef}
			className={`h-100 w-100 p-4 pb-5 pt-5 rounded border border-dashed d-flex flex-column`}
			style={{ backgroundColor: isOver ? '#f5f5f5' : '' }}
			role="list"
		>
			<SortableContext items={schemaElements.map(item => item?.id)} strategy={verticalListSortingStrategy}>
				{schemaElements.length === 0 ? (
					<div className="text-center text-muted py-4">Arrastra elementos aquí</div>
				) : (
					schemaElements.map(item => (
						<div key={item?.id}>
							<SchemaNode key={item?.id} item={item} onFieldUpdate={onFieldUpdate} onRemove={onRemove} className="mb-2" />
						</div>
					))
				)}
			</SortableContext>
			{isDragging && (
				<div className="text-center text-muted py-2">
					<i className="fa fa-plus-circle mr-2" />
					Arrastra elementos aquí
				</div>
			)}
		</div>
	);
};

export default DroppableZone;

import { useContext, useMemo } from 'react';
import { Redirect, useRouteMatch } from 'react-router';
import { AuthContext } from '../../context/AuthContext';
import DashboardApi from '../../api/DashboardApi';
import Dashboard from '../../components/Dashboard/index';
import InternalLayout from '../../components/layouts/InternalLayout';
import Loader from '../../components/Loader';
import UWEContext from '../../context/UWEContext';
import useResourceLoader from '../../util/useResourceLoader';

function TrayView() {
  const auth = useContext(AuthContext);

  const {
    params: { slug },
  } = useRouteMatch();

  const { dashboards } = useContext(UWEContext);

  const { dashboardId, redirectTo } = useMemo(() => {
    let dashboardId = dashboards?.filter(db => db.slug === slug).pop()?.id;
    let redirectTo;

    if (dashboardId === undefined) {
      if (slug === 'home') {
        dashboardId = dashboards[0]?.id;
      } else {
        redirectTo = dashboards[0]?.slug;
      }
    }

    return { dashboardId, redirectTo };
  }, [dashboards, slug]);

  const [dashboard, loadingDashboard] = useResourceLoader(
    async () => (dashboardId ? DashboardApi.get({ id: dashboardId }) : null),
    [dashboardId]
  );

  const TrayComponent = useMemo(() => {
    if (!dashboard) return null;

    const schema = {
      wrapperType: 'internal',
      ...dashboard,
      schema: dashboard.definition,
    };

    return Dashboard.HOC(schema);
  }, [auth, dashboard]);

  const isLoading = loadingDashboard;

  return isLoading ? (
    <InternalLayout>
      <Loader />
    </InternalLayout>
  ) : redirectTo ? (
    <Redirect to={`/workflow/${redirectTo}/tray`} />
  ) : TrayComponent ? (
    <TrayComponent />
  ) : (
    <ErrorTray />
  );
}

const ErrorTray = Dashboard.HOC({
  wrapperType: 'internal',
  schema: {
    type: 'dashboard',
    elements: [
      {
        type: 'text',
        label: 'Tray not Found',
      },
    ],
  },
});

export default TrayView;


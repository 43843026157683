import React, { useState, useEffect } from 'react';
import { FormGroup } from 'reactstrap';
import InputMask from 'react-input-mask';
import { useDebouncedEffect } from '../../hooks/useDebounceEffect';

export default function MoneyInput({
  id,
  value,
  required,
  disabled,
  readonly,
  autofocus,
  onChange: onChangeForm,
}) {
  const [current, setCurrent] = useState(value);

  useDebouncedEffect(
    () => {
      if (current !== value) {
        onChangeForm(current);
      }
    },
    [current],
    450
  );

  useEffect(() => setCurrent(value), [value]);

  const maskToCurrency = ({ nextState }) => {
    let { value } = nextState || {};

    value = value?.replace?.(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    console.log('print value', value);

    return {
      ...nextState,
      value,
    };
  };


  return (
    <FormGroup>
      <InputMask
        disabled={disabled}
        className="form-control"
        value={current || ''}
        required={required}
        beforeMaskedStateChange={maskToCurrency}
        onChange={(e) => setCurrent(e.target.value)}
        readOnly={readonly}
        autoFocus={autofocus}
        id={id}
      />
    </FormGroup>
  );
}

import React, { useMemo, useContext } from 'react';
import DashboardApi from '../api/DashboardApi';
import Loader from '../components/Loader';
import useResourceLoader from '../util/useResourceLoader';
import { AuthContext } from './AuthContext';

const UWEContext = React.createContext();


UWEContext.Component = function UWEContext_Component(props) {
  const { children } = props;
  const { value, loading } = useUWEContextComponentHooks(props);

  return (
    <UWEContext.Provider value={value}>
      {(loading !== false) ? <Loader fullscreen/> : children}
    </UWEContext.Provider>
  );
};

function useUWEContextComponentHooks() {
  const auth = useContext(AuthContext);

  const [dashboards, loadingDashboards] = useResourceLoader(() => {
    if (auth.user) {
      return DashboardApi.list({
        forMe: 1,
      }).then(data => {
        if (data.items?.length) {
          data.items[0].default = true;
        }

        return data.items;
      });
    }

    return Promise.resolve([]);
  }, [auth]);

  const loading = loadingDashboards;

  const value = useMemo(
    () => ({
      dashboards,
    }),
    [dashboards]
  );

  return {
    value,
    loading,
  };
}

export default UWEContext;

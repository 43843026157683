import { property } from 'lodash';

// move to constant file?
const SCHEMATYPE = {
  object: 'object',
  array: 'array',
  string: 'string',
  number: 'number',
};
export default class Item {
  // Factory Methods for each type
  static object({ id, title = '', properties, isArraySubitem = false }) {
    return {
      id,
      type: SCHEMATYPE.object,
      title,
      icon: '📁',
      isArraySubitem,
      properties, // Array para UI
      items: null,
    };
  }

  static array({
    id,
    title = '',
    itemType = [
      Item.string({
        id: Math.floor(Math.random() * 100000),
        isArraySubitem: true,
      }),
    ],
    isArraySubitem = false,
  }) {
    return {
      id,
      type: SCHEMATYPE.array,
      title,
      icon: '📋',
      isArraySubitem,
      properties: null,
      items: itemType,
    };
  }

  static relation({
    id,
    title = '',
    isArraySubitem = false,
    isRelation = true,
  }) {
    return {
      id,
      type: SCHEMATYPE.array,
      title,
      isArraySubitem,
      isRelation,
      properties: null,
      items: [
        {
          ...{
            ...Item.number({
              id: Math.floor(Math.random() * 100000),
              isArraySubitem: true,
            }),
            // "uwe:entity": "House",
            'uwe:entity': title,
            isRelation,
          },
        },
      ],
    };
  }

  static string({ id, title = '', isArraySubitem = false }) {
    return {
      id,
      type: SCHEMATYPE.string,
      title,
      icon: '📝',
      isArraySubitem,
      properties: null,
      items: null,
    };
  }

  static number({ id, title = '', isArraySubitem = false, ...args }) {
    return {
      id,
      type: SCHEMATYPE.number,
      title,
      icon: '🔢',
      isArraySubitem,
      properties: null,
      items: null,
      ...args,
    };
  }

  static findItemAndParent(schemaItems, nodeId) {
    if (!schemaItems) return;
    const directNode = schemaItems.find((item) => item?.id === nodeId);

    if (directNode) {
      return { node: directNode, parent: null };
    }

    return schemaItems.reduce(
      (result, item) => {
        if (result.node) return result;

        if (item?.properties?.length) {
          const found = Item.findItemAndParent(item.properties, nodeId);

          if (found.node) {
            return { node: found.node, parent: found.parent || item };
          }
        }

        if (item?.type === 'array' && item?.items) {
          const found = Item.findItemAndParent(item.items, nodeId);

          if (found.node) {
            return {
              node: found.node,
              parent: found.parent || item,
            };
          }
        }

        return result;
      },
      { node: null, parent: null }
    );
  }

  static addToParent(schemaItems, nodeToAdd, parentId) {
    return schemaItems.map((item) => {
      if (!item) return;

      if (item.id === +parentId) {
        const added =
          item.type === SCHEMATYPE.array
            ? Item.array({
              ...item,
              itemType: [{ ...nodeToAdd, isArraySubitem: true }],
            })
            : Item.object({
              ...item,
              properties: [...(item.properties || []), nodeToAdd],
            });

        return added;
      }

      if (item.properties) {
        return {
          ...item,
          properties: Item.addToParent(item.properties, nodeToAdd, parentId),
        };
      }

      if (item.items) {
        return {
          ...item,
          items: Item.addToParent(item.items, nodeToAdd, parentId),
        };
      }

      return item;
    });
  }

  static removeItem(schemaItems, nodeId) {
    const filteredItems = schemaItems.filter((item) => item?.id !== nodeId);

    return filteredItems.map((item) => {
      if (!item) return;

      if (item.properties?.length) {
        return {
          ...item,
          properties: this.removeItem(item.properties, nodeId),
        };
      }

      if (item.items) {
        return {
          ...item,
          items: this.removeItem(item.items, nodeId),
        };
      }

      return item;
    });
  }

  static moveItem(schemaItems, parentId, cb) {
    if (!schemaItems) return;
    const itm = schemaItems.map((item) => {
      if (!item) return;

      if (item.id === parentId) {
        const arrmove = cb(item.properties);
        const obj = {
          ...item,
          properties: arrmove,
        };

        return obj;
      }

      if (item.properties?.length) {
        const add = {
          ...item,
          properties: this.moveItem(item.properties, parentId, cb),
        };

        return add;
      }

      if (item.items?.length) {
        const add = {
          ...item,
          items: this.moveItem(item.items, parentId, cb),
        };

        return add;
      }

      return item;
    });

    return itm;
  }

  static fromSchema(schema) {
    if (!schema) return null;

    const generateRandomId = () => Math.floor(Math.random() * 100000);

    const convertSchemaToItem = (key, schema, isArraySubitem = false) => {
      if (!schema) return null;

      switch (schema.type) {
        case SCHEMATYPE.object: {
          // convert properties to array for better data structure for the UI
          const properties = schema.properties
            ? Object.entries(schema.properties).map(([key, value]) =>
              convertSchemaToItem(key, value)
            )
            : [];

          return Item.object({
            id: generateRandomId(),
            title: key,
            properties,
            isArraySubitem,
          });
        }

        case SCHEMATYPE.array: {
          return Item.array({
            id: generateRandomId(),
            title: key,
            itemType: Object.values(schema.items).length
              ? [
                convertSchemaToItem(
                  Object.keys(schema.items)[0],
                  schema.items,
                  true
                ),
              ]
              : undefined,
            isArraySubitem,
          });
        }

        case SCHEMATYPE.string:

        case SCHEMATYPE.number: {
          const relationValues = schema.isRelation
            ? {
              'uwe:entity': schema.title,
              isRelation: schema.isRelation,
              title: schema.title,
            }
            : {};

          return Item[schema.type]({
            id: generateRandomId(),
            title: key,
            isArraySubitem,
            ...relationValues,
          });
        }

        default:
          console.error(`Unsupported type: ${schema.type}}`);
      }
    };

    // conver the object properties to an array
    const properties = Object.entries(
      schema?.properties || {}
    ).map(([key, value]) => convertSchemaToItem(key, value));

    return Item.object({
      id: generateRandomId(),
      properties,
    });
  }

  static toSchema(items) {
    // using string as the key to maintain the order
    // if the object has a number key the order is lost
    const objectIdentifier = (item) => {
      if (!item) {
        console.error('Invalid item passed to objectIdentifier:', item);

        return '';
      }

      if (items?.length === 1) return `${item?.title}`;

      return items?.some((i) => i?.title !== item?.title)
        ? `${item.title}`
        : `${item?.title} ${item?.id}`;
    };

    const convertItemToSchema = (item, isArraySubitem = false) => {
      if (!item) return {};
      const baseSchema = {
        id: item?.id,
        title: item?.title,
        type: item?.type,
        isArraySubitem,
        ...item,
      };

      switch (item.type) {
        case SCHEMATYPE.string:
        case SCHEMATYPE.number:
          return baseSchema;

        case SCHEMATYPE.object: {
          const convertNestedProperties = item.properties.reduce(
            (acc, propItem) => {
              acc[objectIdentifier(propItem)] = convertItemToSchema(propItem);

              return acc;
            },
            {}
          );

          return {
            ...baseSchema,
            properties: convertNestedProperties || {},
          };
        }

        case SCHEMATYPE.array:
          return {
            ...baseSchema,
            items: {
              ...{
                ...convertItemToSchema(item.items?.[0], true),
              },
            },
          };

        default:
          console.warn(`Unsupported type: ${item?.type}`);

          return baseSchema;
      }
    };

    const properties = items.reduce((acc, item) => {
      acc[objectIdentifier(item)] = convertItemToSchema(item);

      return acc;
    }, {});

    return Item.object({
      properties,
    });
  }
}

import React from 'react';
import { useConfiguration } from '../../context/ConfigurationContext';

export const Logo = ({ style, width = 72, height = 72 }) => {
  const { LOGO_SRC } = useConfiguration();

  return (
    <img src={LOGO_SRC} style={style} width={width} height={height} alt="logo" />
  );
};


import { t } from '@lingui/macro';
import React, { useMemo } from 'react';
import Dashboard from '../../../components/Dashboard/index';


function ListObjectSchema() {
  const schema = useMemo(
    () => ({
      'title': t`Entity Types`,
      'type': 'dashboard',
      'elements': [
        {
          'title': t`Entity Types`,
          'type': 'worktray',
          ':className': 'col-lg-12',
          'resource': 'manage/objectschemas',
          'tableActions': [
            {
              'label': t`Create Entity Type`,
              'route': '?objectSchema=new',
            },
          ],
          'filters': [
            { 'title': t`Name`, 'key': 'name', 'type': 'string' },
          ],
          'columns': [
            { 'title': t`Name`, 'sortkey': 'name', 'map:field': 'name' },
            { 'title': t`Version`, 'sortkey': 'version', 'map:field': 'version'},
            { 'title': t`Last Updated`, 'sortkey': 'lastUpdated', 'map:field': 'lastUpdated', 'format': 'date'},
            {
              'title': t`Actions`,
              'actions': [
                {
                  'label': t`Edit`,
                  'route': '?objectSchema=$id',
                },
              ],
            },
          ],
        },
      ],
    }),
    []
  );

  return <Dashboard schema={schema} />;
}

export default ListObjectSchema;

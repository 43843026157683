import { t } from '@lingui/macro';
import React, { useMemo } from 'react';
import Dashboard from '../../../components/Dashboard/index';

function ListSequence() {
  const schema = useMemo(
    () => ({
      type: 'dashboard',
      elements: [
        {
          title: t`Sequences`,
          type: 'worktray',
          ':className': 'col-lg-12',
          resource: 'manage/sequences',
          tableActions: [
            {
              label: t`Create Sequence`,
              route: '?sequence=new',
            },
          ],
        'filters': [
        { title: t`Name`, key: 'name', type: 'string' }
          ],
          'columns': [
            { title: t`Name`, sortkey: 'name', 'map:field': 'name' },
            { title: t`Current Number`, 'map:field': 'currentNumber' },
            { title: t`Version`, sortkey: 'version', 'map:field': 'version' },
            { title: t`Last Updated`, sortkey: 'lastUpdated', 'map:field': 'lastUpdated', format: 'date' },
            {
              title: t`Actions`,
              actions: [
                {
                  label: t`Edit`,
                  route: '?sequence=$id',
                },
              ],
            },
          ],
        },
      ],
    }),
    []
  );

  return <Dashboard schema={schema} />;
}

export default ListSequence;

import { Label, Row, Col } from 'reactstrap';
import RelactionSection from './RelationSection';

const RelationLayout = ({ isLoading, fowardRelations, backwardRelations }) => {
  return (
    <>
      <h3 className="text-white p-2" style={{ backgroundColor: '#306aac' }}>
        Relations
      </h3>
      <Row className="py-2">
        <Col className="pl-5" xs="6">
          <Label className="h5" for="fowardRelations">
            Foward Relations
          </Label>
          {isLoading ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          ) : (
            <RelactionSection relationData={fowardRelations} />
          )}
        </Col>
        <Col xs="6">
          <Label className="h5" for="backRelations">
            Back Relations
          </Label>
          {isLoading ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          ) : (
            <RelactionSection relationData={backwardRelations} isBackWardRelation />
          )}
        </Col>
      </Row>
    </>
  );
};

export default RelationLayout;

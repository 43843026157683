import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import { Button } from 'reactstrap';
import { AuthContext } from '../context/AuthContext';
import FormComponent from '../components/FormComponent';
import ExternalLayout from '../components/layouts/ExternalLayout';
import { useConfiguration } from '../context/ConfigurationContext';

const LoginForm = FormComponent({
  displayName: 'Login',
  schema: {
    title: 'Login',
    type: 'object',
    properties: {
      email: { title: 'Email', type: 'string' },
      password: { title: 'Password', type: 'string' },
    },
    required: ['email', 'password'],
  },
  uiSchema: {
    password: { 'ui:widget': 'password' },
  },
  parseProps: () => ({
    auth: useContext(AuthContext),
  }),
  async onSubmit({ formData: { email, password } }, { props: { auth } }) {
    await auth.login({ email, password });
  },
  renderFormChildren: ({ history }) => {
    const configuration = useConfiguration();

    function forgotPassword() {
      history.push('/forgot-password');
    }

    function signUp() {
      history.push('/anonymous/1/workflow/onboarding/new');
    }

    return (
      <>
        <div>
          <Button color="primary" type="submit">
            Acceder
          </Button>
          <Button color="link" onClick={forgotPassword} size="sm">
            Olvidé mi contraseña
          </Button>
        </div>
        <hr />
        <div className="text-center">
          <h5 className="text-bold">¿Necesitas crear una cuenta?</h5>
          {configuration.ENABLE_REGISTRATION_WORKFLOW ? (
            <Button color="primary" onClick={signUp} block>Registrarme</Button> 
          ) : (
            <p className="text-secondary">Ponte en contacto con tu administrador de sistemas</p>
          )}
        </div>
      </>
    );
  },
  renderFormSubmitted: () => (
    <Redirect path="/" />
  ),
  withoutNavigation: true,
});

const Login = ({ history }) => (
  <ExternalLayout>
    <div className="div-login">
      <LoginForm history={history} />
    </div>
  </ExternalLayout>
);


export default Login;


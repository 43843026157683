import { useEffect, useState, useRef } from 'react';
import { Row, Col, Input, Button, Card, CardHeader, Collapse, CardBody } from 'reactstrap';
import JnxField from '../../../components/_formEditor/ObjectEditorField/JnxField';

const MetadataLayout = ({ metadataObjectMap = [], onObjectSchemaChange, onMetadataTest, resultMetadataTest, isLoading }) => {
  const [fields, setFields] = useState(metadataObjectMap);
  const [isAccordionOpen, setAccordionOpen] = useState(false);
  const [testId, setTestId] = useState('');
  const isInitialized = useRef(false);

  const updateFields = operationCb => {
    setFields(current => {
      const newFields = [...current];
      operationCb(newFields);
      const metadataObject = newFields.reduce(
        (acc, field) => ({
          ...acc,
          [field.name]: field.value,
        }),
        {}
      );
      onObjectSchemaChange({ metadataObjectMap: metadataObject });
      return newFields;
    });
  };
  useEffect(() => {
    if (!isInitialized.current && metadataObjectMap.length) {
      setFields(metadataObjectMap);
      isInitialized.current = true;
    }
  }, [metadataObjectMap]);

  const handleMetadataTest = () => {
    const metadataObject = fields.reduce(
      (acc, field) => ({
        ...acc,
        [field.name]: field.value,
      }),
      {}
    );
    onMetadataTest({
      id: testId,
      metadataObjectMap: metadataObject,
    });
  };

  return (
    <>
      <h3 className="text-white p-2" style={{ backgroundColor: '#306aac' }}>
        Metadata
      </h3>
      <Row className="py-2">
        <Col className="pl-5" xs="6">
          <Button
            onClick={() =>
              updateFields(fields => {
                fields.push({
                  id: `field_${fields.length + 1}`,
                  name: `newKey${fields.length + 1}`,
                  value: 'NewValue',
                });
              })
            }
          >
            <i className="fa fa-plus"></i>
          </Button>
          {fields.map((field, index) => (
            <div key={field.id}>
              <div className="d-flex mt-3">
                <Input
                  className=""
                  style={{ flexBasis: '55%' }}
                  type="text"
                  defaultValue={field.name}
                  onBlur={e => {
                    if (e.target.value !== field.name) {
                      updateFields(fields => {
                        fields[index] = {
                          ...fields[index],
                          name: e.target.value,
                        };
                      });
                    }
                  }}
                />
                <Button
                  className="ml-2 flex-shrink-0"
                  onClick={() => {
                    updateFields(fields => {
                      fields.splice(index, 1);
                    });
                  }}
                >
                  X
                </Button>
              </div>
              <JnxField
                fieldProps={{ hideDebug: true, hideScope: true, hideModal: true }}
                value={field.value}
                object={null}
                onChange={newValue =>
                  updateFields(fields => {
                    fields[index] = {
                      ...fields[index],
                      value: newValue,
                    };
                  })
                }
              />
            </div>
          ))}
        </Col>
        <Col xs="6">
          <Card>
            <CardHeader
              className="d-flex justify-content-between align-items-center"
              onClick={() => setAccordionOpen(!isAccordionOpen)}
              style={{ cursor: 'pointer' }}
            >
              <span>Test Metadata</span>
              <i className={`fa fa-chevron-${isAccordionOpen ? 'up' : 'down'}`} />
            </CardHeader>
            <Collapse isOpen={isAccordionOpen}>
              <CardBody className="mt-1">
                <p className="font-weight-bold h5"> Entity Id</p>
                <div className="d-flex mt-3">
                  <Input style={{ flexBasis: '55%' }} type="text" value={testId} onChange={e => setTestId(e.target.value)} />
                  <Button className="ml-2 flex-shrink-0" onClick={handleMetadataTest} disabled={isLoading}>
                    {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> : 'Test'}
                  </Button>
                </div>
                {!!resultMetadataTest ? <h5 className="mt-3 ml-5"> Result</h5> : null}
                {Object.entries(resultMetadataTest || {}).map(([key, value]) => (
                  <Row>
                    <Col className="ml-5" xs={3}>
                      {key}
                    </Col>
                    <Col className="p-2" style={{ backgroundColor: '#eeeeee' }} xs={3}>
                      <span className="ml-2">{value}</span>
                    </Col>
                  </Row>
                ))}
              </CardBody>
            </Collapse>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MetadataLayout;

import { useContext } from 'react';
import { t } from '@lingui/macro';
import FormComponent from '../../../components/FormComponent';
import { AuthContext } from '../../../context/AuthContext';
import ThemeSchemaApi from '../../../api/ThemeSchemaApi';
import { useHistory } from 'react-router-dom';

function SelectThemeForm({ setThemeId }) {
  const history = useHistory();

  const nameProperties = {
    themes: {
      title: t`Themes`,
      type: 'string',
      lookup: {
        api: 'api:manage/themes/all',
        resource: t`Themes`,
        id: 'id',
        label: 'name',
        jnx: 'data',
        setObjectAs: 'selectedThemeObject',
      },
      'ui:field': 'LookupFormField',
    },
  };

  const ThemeForm = FormComponent({
    schema: {
      title: 'Theme Editor',
      type: 'object',
      properties: nameProperties,
      required: Object.keys(nameProperties),
    },
    submitButtons: {
      edit: {
        text: t`Edit`,
        className: 'btn-primary',
        'ui:disableIf': 'selectedThemeObject.name = "Default"',
        onBeforeSubmit: submitEvent => {
          submitEvent.preventDefault();

          const {
            formData: { themes },
          } = submitEvent;

          setThemeId(parseInt(themes));
        },
      },
      delete: {
        text: t`Delete`,
        className: 'btn-warning',
        'ui:disableIf': 'selectedThemeObject.name = "Default"',
        onBeforeSubmit: async submitEvent => {
          submitEvent.preventDefault();

          const {
            formData: { themes },
          } = submitEvent;

          const { success } = await ThemeSchemaApi.delete(parseInt(themes));

          if (success) {
            history.push(history.location.pathname);
          }
        },
      },
      duplicate: {
        text: t`Duplicate`,
        className: 'btn-success',
        onBeforeSubmit: async submitEvent => {
          submitEvent.preventDefault();

          const {
            formData: { themes },
          } = submitEvent;

          const { success, data } = await ThemeSchemaApi.duplicate(parseInt(themes));

          if (success) {
            setThemeId(parseInt(data.id));
          }
        },
      },
    },
    uiSchema: {
      themeName: {
        'ui:options': {
          orderable: false,
        },
      },
    },
    parseProps: ({ setThemeId }) => ({
      auth: useContext(AuthContext),
      setThemeId,
    }),
    async onSubmit({ formData: { themes } }) {},
  });

  return <ThemeForm />;
}

function SelectTheme({ setThemeId }) {
  return <SelectThemeForm setThemeId={setThemeId} />;
}

export default SelectTheme;

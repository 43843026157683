import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors, DragOverlay } from '@dnd-kit/core';

import DraggableSchemaElement from './DraggableSchemaElement';
import DroppableZone from './DroppableZone';
import { SchemaElement } from './SchemaElement';
import { SchemaNodeItem } from './SchemaNodeItem';

const SchemaBuilderLayout = ({
  schemaFieldType,
  schemaElements,
  activeSchemaElement,
  removeSchemaElement,
  updateSchemaElement,
  onDragStart,
  onDragEnd,
}) => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

	return (
		<>
			<h3 className="text-white" style={{ backgroundColor: '#306aac' }}>
				Schema
			</h3>
			<DndContext onDragStart={onDragStart} onDragEnd={onDragEnd} sensors={sensors}>
				<Row className="flex-grow-1 px-3">
					<Col xs="4">
						<Card className="h-100">
							<CardHeader className="bg-light">
								<h5 className="mb-0">Tipos Disponibles</h5>
							</CardHeader>
							<CardBody className="p-2 h-100">
								{schemaFieldType.map(type => (
									<DraggableSchemaElement key={type.id} type={type} />
								))}
							</CardBody>
						</Card>
					</Col>
					<Col xs="8">
						<Card className="h-100">
							<CardHeader className="bg-light">
								<h5 className="mb-0">Área de Construcción</h5>
							</CardHeader>
							<CardBody className="p-3">
								<DroppableZone schemaElements={schemaElements} onRemove={removeSchemaElement} onFieldUpdate={updateSchemaElement} isDragging={!!activeSchemaElement} />
							</CardBody>
						</Card>
					</Col>
				</Row>
				<DragOverlay dropAnimation={null}>
					{activeSchemaElement && (
						<>
							{activeSchemaElement.data.current?.isNew ? (
								<SchemaElement icon={activeSchemaElement.data.current.icon} label={activeSchemaElement.data.current.label} />
							) : (
								<SchemaNodeItem
									isArrayItem={activeSchemaElement.data.current.title === 'items'}
									title={activeSchemaElement.data.current.label}
									type={activeSchemaElement.data.current.type}
									hasChildren={['object', 'array'].includes(activeSchemaElement.data.current.type)}
								/>
							)}
						</>
					)}
				</DragOverlay>
			</DndContext>
		</>
	);
};

export default SchemaBuilderLayout;

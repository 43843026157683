import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import WorkflowApi from '../../../api/WorkflowApi';
import useActionReducer from '../../../util/useActionReducer';
import useResourceLoader from '../../../util/useResourceLoader';
import useSearchParams from '../../../util/useSearchParams';
import workflowDiagramActions from './actions';
import WorkflowDiagram from './WorkflowDiagram';
import WorkflowJson from './WorkflowJson';
import { getLabel } from '../../../LanguageConstant';
import useLoader from '../../../util/useLoader';
import Loader from '../../../components/Loader';
import Notification from '../../../components/Notification';
import useTitle from '../../../util/useTitle';
import { ButtonBar, ButtonBarContext, useButtonGroup, useNewButtonBarContext } from '../../../components/ui/ButtonBar';
import SimpleDropdown from '../../../components/ui/SimpleDropdown';

const TABS = {
  'diagram': { title: 'Diagram', component: WorkflowDiagram },
  'json': { title: 'Json', component: WorkflowJson },
};

const DEFAULT_TAB = Object.keys(TABS)[0];

const MAKE_NEW_WORKFLOW = () => (
  {
    definition: {},
    name: 'new-workflow',
    paramSchema: {},
    roles: []
  }
);

function WorkflowIndex({ workflowId: _workflowId, setWorkflow: setWorkflowId }) {
  const [resetCt, setResetCt] = useState(0);
  const workflowIdName = useMemo(() => (
    _workflowId?.startsWith('name-') ?
    _workflowId.substring(5) :
    undefined
  ), [_workflowId]);

  const [workflow, loadingWorkflow, errorLoadingWorkflow] = useResourceLoader(() => {
    if (_workflowId === 'new') return MAKE_NEW_WORKFLOW();

    if (workflowIdName) {
      return WorkflowApi.getByName({ name: workflowIdName });
    }

    return WorkflowApi.get({ workflowId: _workflowId });
  }, [workflowIdName, _workflowId, resetCt]);
  const workflowId = workflow?.id ?? _workflowId;

  const [loadingSetWorkflow, errorLoadingSetWorkflow, loadFn] = useLoader();

  const [
    [tab, setTab]
  ] = useSearchParams({
    tab: DEFAULT_TAB
  });

  useTitle(
    workflow ? (
        workflowId === 'new' ? 'New Workflow': `Edit Workflow | ${workflow.name}`
    ) : 'Loading'
  );

  const [state, dispatchAction] = useActionReducer(workflowDiagramActions, {
    steps: {},
    arrows: [],
  });

  useEffect(() => {
    if (workflow) {
      dispatchAction.setWorkflow(workflow);
    }
  }, [workflow]);

  const save = useCallback(() => {
    const wf = {
      ...workflow,
      definition: { ...state.steps },
      roles: state.roles,
      name: state.name,
      objectSchemaId: state.objectSchemaId,
      ...state.details,
      paramSchema: state.paramSchema,
    };

    if (workflowId === 'new') {
      loadFn(() =>
        WorkflowApi.create(wf).then(workflow => {
          setWorkflowId(workflow.data.id);
        })
      );
    } else {
      loadFn(() => WorkflowApi.update(wf.id, wf));
    }
  }, [workflow, state, workflowId, WorkflowApi, loadFn, setWorkflowId]);

  const loading = loadingWorkflow || loadingSetWorkflow;
  const error = errorLoadingWorkflow || errorLoadingSetWorkflow;
  const currentTab = TABS[tab];
  const Component = currentTab?.component;

  const bbc = useNewButtonBarContext();
  useButtonGroup(
    bbc,
    () => ({
      name: 'save button',
      order: 0,
      align: 'left',

      buttons: [
        <Button
          key={1}
          color="success"
          size="sm"
          onClick={save}
          title={getLabel().save}
        >
          <i className="fa fa-save" />
        </Button>,
        <Button
          key={2}
          variant="secondary"
          size="sm"
          onClick={() => {
            setResetCt(new Date().getTime());
          }}
          title={getLabel().reset}
        >
          <i className="fa fa-undo" />
        </Button>,
      ],
    }),
    [save, getLabel, setResetCt]
  );

  const tabOptions = useMemo(() => Object.entries(TABS).map(
    ([value, { title }]) => ({ value, title })
  ), []);

  return (
    <div>
      <ButtonBarContext.Provider value={bbc}>
        <div className="d-flex">
          <SimpleDropdown value={tab} options={tabOptions} onSelect={setTab} />
          <ButtonBar className="d-flex" />
        </div>
        {error ? <Notification error={error} /> : null}
        {loading ? (
          <Loader fullscreen />
        ) : (Component ? (
          <Component workflow={workflow} state={state} dispatchAction={dispatchAction} />
        ) : null)}
      </ButtonBarContext.Provider>
    </div>
  );
}


export default WorkflowIndex;

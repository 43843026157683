import React, { useEffect, useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import { FormGroup, Form } from 'reactstrap';

import { arrayMove } from '@dnd-kit/sortable';

import Item from './Item';
import useResourceLoader from '../../../util/useResourceLoader';
import ObjectSchemaApi from '../../../api/ObjectSchemaApi';
import EntityInfoLayout from './EntityInfoLayout';
import SchemaBuilderLayout from './SchemaBuilderLayout';
import MetadataLayout from './MetadataLayout';
import RelationLayout from './RelationLayout';
import UWEEntityApi from '../../../api/UWEEntityApi';
import Notification from '../../../components/Notification';

const draggableSchemaFieldType = [
  { id: 'string', label: 'Texto', icon: '📝' },
  { id: 'number', label: 'Número', icon: '🔢' },
  { id: 'object', label: 'Objeto', icon: '📁' },
  { id: 'array', label: 'Lista', icon: '📋' },
  { id: 'relation', label: 'Relacion', icon: '🔗' },
];

let elementCounterId = 0;

const ObjectSchemaEditor = forwardRef((props, ref) => {
  const { objectSchemaState, onObjectSchemaChange } = props;
  const [activeId, setActiveId] = useState(null);
  const [schemaElements, setSchemaElements] = useState(null);
  const [metadataTest, setMetadataTest] = useState(null);

  const [result, isLoadingSchemaList, listSchemaError] = useResourceLoader(async () => {
    return ObjectSchemaApi.list({ offset: 0, size: 30 });
  }, []);

  const [metadataTestResult, isMetadataResultLoading, metadataTestError] = useResourceLoader(async () => {
    if (metadataTest) {
      return UWEEntityApi.previewMetadata(metadataTest);
    }
  }, [metadataTest]);

  const handleMetadataTest = async ({ id, metadataObjectMap }) => {
    setMetadataTest({ id, metadataObjectMap });
  };

  useImperativeHandle(ref, () => ({
    getItems: () => schemaElements,
  }));

  useEffect(() => {
    if (objectSchemaState?.schema) {
      setSchemaElements(Item.fromSchema(objectSchemaState?.schema)?.properties);
    }
  }, [objectSchemaState?.schema]);

  const handleFieldUpdate = useCallback(
    (nodeId, newTitle) => {
      const updateNodeTitle = schemaElements => {
        return schemaElements.map(element => {
          if (!element) return null;

          if (element.id === nodeId && element['uwe:entity']) {
            return { ...element, title: newTitle, 'uwe:entity': newTitle };
          }

          if (element.id === nodeId) {
            return { ...element, title: newTitle };
          }

          if (element.properties?.length) {
            return {
              ...element,
              properties: updateNodeTitle(element.properties),
            };
          }

          if (element.items?.length) {
            return {
              ...element,
              items: updateNodeTitle(element.items),
            };
          }

          return element;
        });
      };

      setSchemaElements(schemaElements => {
        return updateNodeTitle(schemaElements);
      });
    },
    [setSchemaElements]
  );

  const handleRemoveNode = useCallback(
    nodeId => {
      setSchemaElements(schemaElements => Item.removeItem(schemaElements, nodeId));
    },
    [setSchemaElements]
  );

  function handleDragAndDrop(event) {
    const { active: draggedElement, over: dropTarget } = event;
    setActiveId(null);

    if (!dropTarget) return;

    // Handle new schema node creation from palette
    if (draggedElement.data.current?.isNew) {
      const schemaType = draggableSchemaFieldType.find(type => type.id === draggedElement.id);
      if (!schemaType) return null;

      const schemaNodeFactory = {
        string: ({ id, title }) => Item.string({ id, title }),
        number: ({ id, title }) => Item.number({ id, title }),
        object: ({ id, title }) => Item.object({ id, title, properties: [] }),
        array: ({ id, title, itemType = undefined }) => Item.array({ id, title, itemType }),
        relation: ({ id, title }) => Item.relation({ id, title }),
      };

      const randomId = Math.floor(Math.random() * 100000);
      const newSchemaNode = schemaNodeFactory[schemaType.id]({
        id: randomId,
        title: `${schemaType.label}_${elementCounterId}`,
      });
      elementCounterId = elementCounterId + 1;

      return setSchemaElements(schemaElements => handleNewSchemaNodeDrop(schemaElements, newSchemaNode, dropTarget.id));
    }

    // move between container
    if (dropTarget.id === 'editor-droppable' || dropTarget.id.toString().endsWith('-container')) {
      return setSchemaElements(schemaElements => handleMoveSchemaNode(schemaElements, draggedElement.id, dropTarget.id));
    }

    // move inside a container / or editor
    if (draggedElement.id !== dropTarget.id) {
      return setSchemaElements(schemaElements => handleReorderSchemaNodes(schemaElements, draggedElement.id, dropTarget.id));
    }
  }

  const handleNewSchemaNodeDrop = (schemaElements, newNode, targetId) => {
    // Drop in main editor area
    if (targetId === 'editor-droppable') {
      return [...schemaElements, newNode];
    }

    // Drop in container
    if (targetId.toString().endsWith('-container')) {
      const containerId = targetId.replace('-container', '');
      const add = Item.addToParent(schemaElements, newNode, +containerId);

      return add;
    }

    return schemaElements;
  };

  const handleMoveSchemaNode = (schemaElements, nodeId, targetId) => {
    const { node: nodeToMove } = Item.findItemAndParent(schemaElements, nodeId);
    if (!nodeToMove) return schemaElements;
    const itemsWithoutNode = Item.removeItem(schemaElements, nodeId);

    if (targetId === 'editor-droppable') {
      return [...itemsWithoutNode, nodeToMove];
    } else {
      const containerId = targetId.toString().replace('-container', '');

      return Item.addToParent(itemsWithoutNode, nodeToMove, +containerId);
    }
  };

  const handleReorderSchemaNodes = (schemaElements, draggedId, dropTargetId) => {
    const { parent: sourceParent } = Item.findItemAndParent(schemaElements, draggedId);
    const localSchema = sourceParent ? sourceParent.properties : schemaElements;
    const oldIndex = localSchema.findIndex(item => item?.id === draggedId);
    const newIndex = localSchema.findIndex(item => item?.id === dropTargetId);

    if (sourceParent) {
      const newSchemaElements = Item.moveItem(schemaElements, sourceParent.id, containerSchemaElemenents =>
        arrayMove(containerSchemaElemenents, oldIndex, newIndex)
      );

      return newSchemaElements;
    } else {
      const newSchemaElements = arrayMove(schemaElements, oldIndex, newIndex);

      return newSchemaElements;
    }
  };

  // relation section state
  function transformRelation(relation, result) {
    if (!Array.isArray(result)) {
      return { path: '', relation: '' };
    }

    const targetName = result?.find(res => res.id === relation?.targetId)?.name;
	console.log("targetName", targetName);
	const sourceName = result?.find(res => res.id === relation?.sourceId)?.name;
    return {
	  sourceName: sourceName,
      relationNameField: relation?.sourceProperty,
      targetName: targetName,
    };
  }

  const processRelations = (objectSchema, result) => {
    const fwdRels = objectSchema?.fwdRelations || [];
    const backRels = objectSchema?.backRelations || [];


    const transformed = {
      fwdRelations: fwdRels.filter(relation => relation?.targetId).map(relation => transformRelation(relation, result)),
      backRelations: backRels.filter(relation => relation?.sourceId).map(relation => transformRelation(relation, result)),
    };

    return transformed;
  };

  const relationData = processRelations(objectSchemaState, result?.data.items);
  const error = listSchemaError || metadataTestError;

  return (
    <Form className="bg-white d-flex flex-column" style={{ minHeight: '100vh' }}>
      {error ? <Notification error={error} /> : null}
      <FormGroup>
        <EntityInfoLayout
          info={{
            name: objectSchemaState?.name,
            version: objectSchemaState?.version,
            lastUpdated: objectSchemaState?.lastUpdated,
          }}
          onObjectSchemaChange={onObjectSchemaChange}
        />
      </FormGroup>
      <FormGroup className="flex-grow-1 d-flex flex-column">
        <SchemaBuilderLayout
          schemaElements={schemaElements}
          schemaFieldType={draggableSchemaFieldType}
          activeSchemaElement={activeId}
          removeSchemaElement={handleRemoveNode}
          updateSchemaElement={handleFieldUpdate}
          onDragStart={e => setActiveId(e.active)}
          onDragEnd={handleDragAndDrop}
        />
      </FormGroup>
      <FormGroup>
        <MetadataLayout
          metadataObjectMap={Object.entries(objectSchemaState?.metadataObjectMap || {}).reduce((acc, [key, value], currentIndex) => {
            acc.push({ id: `${key}_${currentIndex}`, name: key, value });
            return acc;
          }, [])}
          onObjectSchemaChange={onObjectSchemaChange}
          onMetadataTest={handleMetadataTest}
          resultMetadataTest={metadataTestResult}
          isLoading={isMetadataResultLoading}
        />
      </FormGroup>
      <FormGroup>
        <RelationLayout
          isLoading={isLoadingSchemaList}
          fowardRelations={relationData.fwdRelations}
          backwardRelations={relationData.backRelations}
        />
      </FormGroup>
    </Form>
  );
});

export default ObjectSchemaEditor;

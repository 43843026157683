import { t } from '@lingui/macro';
import React, { useMemo } from 'react';
import { getLabel } from '../../../LanguageConstant';
import Dashboard from '../../../components/Dashboard/index';

const OU_TYPES = {
  'Agency': 'Agencia',
  'Bureau': 'Entidad Peticionaria',
  'WorkDivision': 'División de Trabajo'
};

export function OrganizationalUnitsTable() {
  const schema = useMemo(
    () => ({
      'title': t`Organizational Units`,
      'type': 'dashboard',
      'elements': [
        {
          'title': t`Organizational Units`,
          'type': 'worktray',
          ':className': 'col-lg-12',
          'resource': 'manage/organizationalunit',
          'canSeeDiagram': {
            'const_types': OU_TYPES
          },
          'tableActions': [
            {
              'label': getLabel().agencyRegister,
              'route': '/organizationalunits/create',
              'require': [ {permission: 'ManageOrganizationalUnits'} ]
            },
          ],
          'filters': [
            { 'title': t`Code`, 'key': 'code', 'type': 'string' },
            { 'title': t`Name`, 'key': 'name', 'type': 'string' },
            { 'title': t`City`, 'key': 'city', 'type': 'string' },
            { 'title': t`Postalcode`, 'key': 'postalcode', 'type': 'string' },
            { 'title': t`State`, 'key': 'state', 'type': 'string' },
          ],
          'columns': [
            { 'title': getLabel().code      , 'sortKey': 'code'      , 'map:field': {'expr': '$formatNumber(numericCode, \'000\') & \'-\' & $formatNumber(dependencyFigure, \'0000\')' } },
            { 'title': getLabel().name      , 'sortKey': 'name'      , 'map:field': {'expr': 'name & \' \' & ($isTruthy(code) ? \'(\' & code & \')\' : \'\')'} },
            { 'title': getLabel().city      , 'sortKey': 'city'      , 'map:field': 'city' },
            { 'title': getLabel().postalCode, 'sortKey': 'postalcode', 'map:field': 'postalCode' },
            { 'title': getLabel().state     , 'sortKey': 'state'     , 'map:field': 'state' },
            {
              'title': t`Actions`,
              'actions': [
                {
                  'label': t`Edit`,
                  'route': '/organizationalunits/$id',
                },
              ],
            },
          ],
        },
      ],
    }),
    []
  );

  return <Dashboard schema={schema} />;
}


export default OrganizationalUnitsTable;

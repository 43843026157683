import AppContext from './context/AppContext';
import routes from './routes';
import AuthVerification from './components/AuthVerification';
import UWEContext from './context/UWEContext';
import RouteSwitch from './components/RouteSwitch';
import initAppConfiguration from './hooks/initAppConfiguration';

const ConfigurationProvider = initAppConfiguration({
  LOGO_SRC: "uwe_logo.svg"
});

function App() {
  return (
    <ConfigurationProvider>
      <AppContext.Component>
        <UWEContext.Component>
          <AuthVerification />
          <RouteSwitch routes={routes} />
        </UWEContext.Component>
      </AppContext.Component>
    </ConfigurationProvider>
  );
}

export default App;
